<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #070911;
  &-container {
    width: 400px;
    height: 463px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 3px;
    font-family: "PingFangSC-Regular", "PingFang SC";
    background: center / contain no-repeat url("../assets/tips.png");
    font-weight: 400;
    color: rgba(85, 119, 224, 1);
    box-sizing: border-box;
    padding: 106px 86px;
    &-button {
      width: 140px;
      height: 40px;
      margin: 0 auto;
      margin-top: 59px;
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 29px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>

<template>
  <div class="login">
    <div class="login-container">
      <p>
        {{$t('L29')}} <br />
        {{$t('L30')}}
      </p>
      <div class="login-container-button"
           @click="closeLogin">{{$t('L31')}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'login',
  data () {
    return {
    }
  },
  computed: {
  },
  created () {

  },
  mounted () {
  },
  methods: {
    closeLogin () {
      this.$router.go(-1);
      // this.$router.push({
      //   name: 'fairviewJiangnan'
      // })
    },
  }
}
</script>
